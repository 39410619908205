import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddMoney = () => {
  const loadingCtx = useContext(LoadingContext);
  const formRef = useRef();

  const [qr, setQR] = useState("");

  useEffect(() => {
    getQr();
  }, []);

  const getQr = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.bpAddMoney(token, "1");

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const vpa_id = responseData.data.vpa_id;

        generateQrHandler(vpa_id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const generateQrHandler = (vpaID) => {
    upiqr({
      payeeVPA: vpaID,
      payeeName: "PAN MITRA",
    })
      .then((upi) => {
        setQR(upi.qr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rrnRef = useRef();

  const checkPaymentHandler = async () => {
    const rrn = rrnRef.current.value;

    if (!rrn) {
      Swal.fire("Opps!", "Invalid RRN Number", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.bpCheckStatus(token, rrn);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Money">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add Money</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          {qr && (
                            <div class="card-body border card-border-primary">
                              <div className="text-center plan-btn mt-2 mb-2">
                                <img
                                  src="/assets/img/logo.png"
                                  alt="logo"
                                  height="50px"
                                />
                                <h5 className="mt-4">
                                  Scan this QR code to Add Money
                                </h5>
                                {qr && <img src={qr} alt="upi-qr" />}
                                <h6 className="mb-1">
                                  Scan &amp; Pay with any BHIM UPI App
                                </h6>
                                <img
                                  src="/assets/img//upi_apps.png"
                                  alt="logo"
                                  height="80px"
                                />{" "}
                                <form ref={formRef} className="mt-4">
                                  <div class="form-group">
                                    <input
                                      type="amount"
                                      class="form-control form-control-border"
                                      maxLength={20}
                                      ref={rrnRef}
                                      placeholder="Enter RRN/UTR Number"
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={checkPaymentHandler}
                                  >
                                    Update Payment
                                  </button>
                                </form>
                              </div>
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddMoney;
