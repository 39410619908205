import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";

const Recharge = () => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();

  const [number, setNumber] = useState();
  const [operatorName, setOperatorName] = useState();
  const [operatorId, setOperatorId] = useState();
  const [amount, setAmount] = useState();

  const [open, setOpen] = useState(false);

  const submitHandler = async () => {
    if (!number) {
      Swal.fire("Opps!", "Invalid Recharge Number", "error");
      return;
    }

    if (!operatorId) {
      Swal.fire("Opps!", "Invalid Operator", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    setOpen(true);
  };

  const operators = [
    { name: "AIRTEL", id: "7" },
    { name: "BSNL TOPUP", id: "11" },
    { name: "BSNL SPECIAL", id: "10" },
    { name: "JIO", id: "9" },
    { name: "VI", id: "8" },
    { name: "AIRTEL DIGITAL TV", id: "12" },
    { name: "DISH TV", id: "15" },
    { name: "SUN DIRECT", id: "13" },
    { name: "TATA SKY", id: "16" },
    { name: "VIDEOCON D2H", id: "14" },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [userId, setUserId] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    setUserId(user.user_id);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Recharge">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Recharge</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row mt-3">
                            <label class="col-sm-3 col-form-label">
                              Recharge Number
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Recharge Number"
                                maxLength={15}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                onChange={(e, v) => {
                                  setNumber(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Operator
                            </label>
                            <div class="col-sm-9">
                              <select
                                class="form-control"
                                onChange={(e, v) => {
                                  setOperatorId(e.target.value);
                                  const result = operators.find(
                                    ({ id }) => id === e.target.value
                                  );
                                  setOperatorName(result.name);
                                }}
                              >
                                <option value="">Select Operator</option>

                                {operators.map((e, key) => {
                                  return (
                                    <option key={key} value={e.id}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Amount
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Amount"
                                maxLength={4}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                onChange={(e, v) => {
                                  setAmount(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center mb-3">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <OrderReviewDialog
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          formRef={formRef}
          userId={userId}
          number={open ? number : ""}
          operatorId={open ? operatorId : ""}
          operatorName={open ? operatorName : ""}
          amount={open ? amount : ""}
        />
      </Templete>
    </React.Fragment>
  );
};

export default Recharge;
