import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import $ from "jquery";
window.$ = $;

const OrderReviewDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const pinRef = useRef();

  const getAuthorizationHandler = async (e) => {
    e.preventDefault();

    const pin = pinRef.current.value;

    if (!pin || isNaN(pin)) {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.transaction(
        token,
        props.userId,
        pin,
        props.operatorId,
        props.number,
        props.amount
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Review Order</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-striped table-primary">
                <tr>
                  <th>Recharge Number:</th>
                  <td>{props.number}</td>
                </tr>
                <tr>
                  <th>Operator:</th>
                  <td>{props.operatorName}</td>
                </tr>
                <tr>
                  <th>Payable Amount:</th>
                  <td>₹{props.amount}</td>
                </tr>
              </table>
            </div>

            <div class="input-group mb-3 mt-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={pinRef}
                class="form-control"
                placeholder="Transaction PIN"
                maxLength={4}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={getAuthorizationHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OrderReviewDialog;
