import common from "../../utill/Common";

class WalletService {
  bpAddMoney = async (token, amount) => {
    const url = common.baseUrl + "user/pg/bp_add_money";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  bpCheckStatus = async (token, rrn) => {
    const url = common.baseUrl + "user/pg/bp_check_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        rrn,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  walletStatement = async (token, fromDate, toDate, searchText, page, size) => {
    const url = common.baseUrl + "user/history/wallet";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        search_text: searchText,
        page: page,
        size: size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  xlsWalletStatement = async (token, fromDate, toDate) => {
    const url = common.baseUrl + "user/history/xls_wallet_statement";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  commissionSummary = async (token, fromDate, toDate, product_id) => {
    const url = common.baseUrl + "user/report/commission";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        product_id: product_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  productList = async (token) => {
    const url = common.baseUrl + "user/product/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fundRequestHistory = async (
    token,
    fromDate,
    toDate,
    searchText,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/fund_request/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        search_text: searchText,
        page: page,
        size: size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  walletTransferHistory = async (
    token,
    fromDate,
    toDate,
    searchText,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/report/wallet_transfer";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        search_text: searchText,
        page: page,
        size: size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  walletReceivedHistory = async (
    token,
    fromDate,
    toDate,
    searchText,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/report/wallet_received";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        search_text: searchText,
        page: page,
        size: size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WalletService();
