import common from "../../utill/Common";

class RechargeService {
  transaction = async (token, userId, pin, operator, number, amount) => {
    const url = common.baseUrl + "user/recharge/transaction";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({ operator, number, amount }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RechargeService();
