import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import { useHistory } from "react-router-dom";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import RechargeService from "../../../services/Admin/RechargeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

const DisputeRechargeReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [status, setStatus] = useState();
  const [disputeStatus, setDisputeStatus] = useState("PENDING");
  const [productId, setProductId] = useState();
  const [userId, setUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    RechargeService.disputeReport(
      token,
      fromDate,
      toDate,
      status,
      disputeStatus,
      productId,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },

    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "API Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.recharge_api ? row.recharge_api.name : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Operator",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Operator: {row.product.name}</span>
          <br />
          <span>Number: {row.optional1}</span>
          <br />
          <span>Amount: ₹{row.order_amount}</span>
        </div>
      ),
    },
    // {
    //   name: "Number",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span>{row.optional1}</span>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Amount",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span>₹{row.order_amount}</span>
    //     </div>
    //   ),
    // },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },

    {
      name: "Operator Ref",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.operator_id ? row.operator_id : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Dispute Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.secret_optional5 === "REJECTED") {
              return (
                <h5>
                  <span class="badge badge-danger">REJECTED</span>
                </h5>
              );
            } else if (row.secret_optional5 === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.secret_optional5 === "ACCEPTED") {
              return (
                <h5>
                  <span class="badge badge-secondary">ACCEPTED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Dispute Date Time",
      cell: (row) => (
        <div>
          <span>{row.secret_optional6}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          {row.secret_optional5 == "PENDING" && (
            <IconButton
              color="success"
              aria-label="upload picture"
              component="span"
              onClick={async () => {
                const { value: formValues } = await Swal.fire({
                  title: "Are you sure to update the status to SUCCESS?",
                  html: `
                  <input id="swal-input1" placeholder="Remark" value=${
                    row.remark ? row.remark : ""
                  } class="swal2-input">
                `,
                  focusConfirm: false,
                  showCancelButton: true,
                  confirmButtonText: `Do Success`,
                  confirmButtonColor: "green",
                  preConfirm: () => {
                    return [document.getElementById("swal-input1").value];
                  },
                });
                if (formValues) {
                  updateTxnStatusHandler(row.id, "REJECTED", formValues[0]);
                }
              }}
            >
              <DoneOutlineIcon />
            </IconButton>
          )}

          {row.secret_optional5 == "PENDING" && (
            <IconButton
              color="error"
              aria-label="upload picture"
              component="span"
              onClick={async () => {
                const { value: formValues } = await Swal.fire({
                  title: "Are you sure to update the status to FAILED?",
                  html: `
                <input id="swal-input2" placeholder="Remark" value=${
                  row.remark ? row.remark : ""
                } class="swal2-input">
                `,
                  focusConfirm: false,
                  showCancelButton: true,
                  confirmButtonText: `Do Failure`,
                  confirmButtonColor: "red",
                  preConfirm: () => {
                    return [document.getElementById("swal-input2").value];
                  },
                });
                if (formValues) {
                  updateTxnStatusHandler(row.id, "ACCEPTED", formValues[0]);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const updateTxnStatusHandler = async (id, status, remark) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.updateDisputeTxnStatus(
        token,
        id,
        status,
        remark
      );

      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Dispute Recharge Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Dispute Recharge Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setDisputeStatus(e.target.value);
                                  }}
                                >
                                  <option value="">
                                    Select Dispute Status
                                  </option>
                                  <option value="ACCEPTED">ACCEPTED</option>
                                  <option value="PENDING" selected>
                                    PENDING
                                  </option>
                                  <option value="REJECTED">REJECTED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option value="">Select Operator</option>
                                  <option value="1">Airtel</option>
                                  <option value="2">BSNL Topup</option>
                                  <option value="3">BSNL Special</option>
                                  <option value="4">JIO</option>
                                  <option value="5">Vi</option>
                                  <option value="6">Airtel Digital TV</option>
                                  <option value="7">Dish TV</option>
                                  <option value="8">Sun Direct</option>
                                  <option value="9">Tata Sky</option>
                                  <option value="10">Videocon D2H</option>
                                  <option value="47">Google Play</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                class="btn btn-primary mr-4"
                                onClick={() => {
                                  fetchData();
                                }}
                              >
                                <i class="fa fa-search"></i> Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </React.Fragment>
  );
};

export default DisputeRechargeReport;
