import common from "../../utill/Common";

class WalletService {
  addTransaction = async (
    token,
    id,
    txn_type,
    wallet_type,
    amount,
    reference_id,
    remark
  ) => {
    const url = common.baseUrl + "admin/wallet/add_transaction";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        txn_type,
        wallet_type,
        amount,
        reference_id,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  doSuccess = async (token, id) => {
    const url = common.baseUrl + "admin/transactions/success";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  doReverse = async (token, id) => {
    const url = common.baseUrl + "admin/transactions/reverse";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getTotalBalance = async (token) => {
    const url = common.baseUrl + "admin/wallet/total_balance";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  transactions = async (
    token,
    from_date,
    to_date,
    status,
    category,
    product_id,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/transactions/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        category,
        product_id,
        user_id,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  walletTopupHistory = async (
    token,
    from_date,
    to_date,
    status,
    category,
    product_id,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/wallet/topup_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        category,
        product_id,
        user_id,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  pendingFundRequest = async (token) => {
    const url = common.baseUrl + "admin/wallet/fund_request/pending";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fundRequestHistory = async (
    token,
    from_date,
    to_date,
    user_id,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/wallet/fund_request/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        user_id,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fundRequestStatusUpdate = async (token, id, status, remark) => {
    const url = common.baseUrl + "admin/wallet/fund_request/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        status,
        admin_remark: remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WalletService();
