import React, { useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";
import Templete from "../Templete/Templete";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AuthService from "../../../services/Admin/AuthService";
import RechargeApiService from "../../../services/Admin/RechargeApiService";
import ProductService from "../../../services/Admin/ProductService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const SetOperatorCode = () => {
  const [loading, setLoading] = useState(false);

  const [packageId, setPackageId] = useState();

  const [products, setProducts] = useState();
  const [productId, setProductId] = useState();

  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await ProductService.fetchAll(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const columns = [
    {
      title: "API ID",
      field: "api_id",
      editable: "never",
    },
    {
      title: "API Name",
      field: "api_name",
      editable: "never",
    },
    {
      title: "Operator Code",
      field: "code",
    },
  ];

  const [data, setData] = useState();

  // const getData = () => {
  const getData = React.useCallback(async () => {
    const token = AuthService.getAccessToken();

    loadingCtx.startLoading();

    RechargeApiService.fetchApiCode(token, productId ? productId : "")
      .then((data) => {
        if (data.status === "SUCCESS") {
          const dataResult = data.data.api_code;
          setData(dataResult);
        } else {
          throw new Error(data.message);
        }

        loadingCtx.stopLoading();
      })
      .catch((err) => {
        loadingCtx.stopLoading();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);
  // };

  useEffect(() => {
    getData();
  }, [getData]);

  const defaultMaterialTheme = createTheme();

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Set Operator Code">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Set Operator Code</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <select
                                  class="form-control form-control-border"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option>Select Operator</option>
                                  {products &&
                                    products.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="mb-5">
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <MaterialTable
                          components={{
                            Container: (props) => (
                              <Paper {...props} elevation={0} />
                            ),
                          }}
                          columns={columns}
                          data={data}
                          options={{
                            search: false,
                            paging: false,
                            showTitle: false,

                            headerStyle: {
                              backgroundColor: "#DEF3FA",
                              color: "Black",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            },
                            cellStyle: {
                              textAlign: "center",
                            },
                          }}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise(async (resolve, reject) => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                const token =
                                  await AuthService.getAccessToken();
                                RechargeApiService.updateApiCode(
                                  token,
                                  productId,
                                  newData
                                )
                                  .then((data) => {
                                    getData();
                                  })
                                  .catch((error) => {
                                    getData();
                                  });
                                resolve();
                              }),
                            onRowDelete: (oldData) =>
                              new Promise(async (resolve, reject) => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                const token =
                                  await AuthService.getAccessToken();
                                RechargeApiService.deleteApiCode(
                                  token,
                                  productId,
                                  oldData
                                )
                                  .then((data) => {
                                    getData();
                                  })
                                  .catch((error) => {
                                    getData();
                                  });
                                resolve();
                                // STOP LOADING
                                loadingCtx.stopLoading();
                              }),
                          }}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default SetOperatorCode;
